import currency from "currency.js"

export default function GiftCard(mid, active, cardNumber, balance, customerID, transactionID) {
  this.doctype = "giftcard"
  this.mid = mid
  this.active = active
  this.isDeleted = false
  this.cardNumber = cardNumber
  this.balance = balance
  this.customerID = customerID
  this.balanceStandard = [{ transactionID: transactionID, amount: currency(balance).intValue, isVoid: false }]

  this.resolveDocument = function () {
    return {
      doctype: this.doctype,
      mid: this.mid,
      active: this.active,
      isDeleted: this.isDeleted,
      cardNumber: this.cardNumber,
      balance: currency(this.balance).intValue,
      customerID: this.customerID,
      balanceStandard: this.balanceStandard
    }
  }

  this.resolveForm = function () {
    return {
      active: this.active,
      cardNumber: this.cardNumber,
      balance: currency(this.balance).intValue,
      customerID: this.customerID
    }
  }
}