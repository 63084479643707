import React from "react"
import { Box, Grid } from "@mui/material"
import approvedGif from "../../public/approved.gif"

const MAX_RETRY = 10

export default function Complete() {
  const [retryCount, setRetryCount] = React.useState(0)
  React.useEffect(async () => {
    if (retryCount < MAX_RETRY) {
      console.log("Retry Count: ", retryCount)

      const params = new URLSearchParams(document.location.search)
      const ticket = atob(params.get("ticket"))
      const ticketData = JSON.parse(ticket)
      console.log("Ticket", ticketData)

      if (ticketData.invoicenumber) {
        const response = await fetch("/api/epn/get-transaction-response", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            invoicenumber: ticketData.invoicenumber,
            username: ticketData.username,
            password: ticketData.password,
            key: ticketData.key
          })
        })

        const data = await response.json()
        console.log(data)

        if (data.number_of_records > 0) {
          const transaction = data.data[0]
          console.log(transaction)
          if (transaction.REF_TXN_ID) {
            localStorage.setItem("INCOMING_REF_TXN_ID", transaction.REF_TXN_ID)
            setTimeout(() => window.close(), 3000)
          }
        } else { setRetryCount(retryCount + 1) }
      } else { console.log("Not enough ticket data!") }
    }
  }, [retryCount])

  return (
    <Box height="100vh" bgcolor="primary.contrastText" paddingTop={15}>
      <Grid container justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <img src={approvedGif} alt="..." />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <h1>APPROVED</h1>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <h4>Your transaction has been successfully completed!</h4>
        </Grid>
      </Grid>
    </Box>
  )
}