import currency from "currency.js"

export default function TicketMaster(mid, cash, credit, tax, fee, feeTax, discount, tip, command, status) {
  this.mid = mid
  this.cash = currency(cash).intValue
  this.credit = currency(credit).intValue
  this.cashTax = currency(tax).intValue
  this.fee = currency(fee).intValue
  this.feeTax = currency(feeTax).intValue
  this.discountApplied = currency(discount).intValue
  this.tip = currency(tip).intValue
  this.command = command
  this.status = status

  // this.cashTotal = this.cash + this.cashTax
  // this.creditTax = this.cashTax + this.feeTax
  // this.creditTotal = this.credit + this.creditTax
}