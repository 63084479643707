import currency from "currency.js"
import GiftCard from "../documents/GiftCard"
import TicketDetail from "../documents/TicketDetail"
import TicketMaster from "../documents/TicketMaster"
import { IS_EPN, IS_REEF } from "../global"

export function getDateByOffset(offset) {
  const date = new Date()
  date.setDate(date.getDate() + offset)
  return date
}

/*
  y = year, m = month, d = day
  ymd = 2024/05/14
  ydm = 2024/14/05
  mdy = 05/14/2024
  yyy = 2024/2024/2024
*/
export function formatDate(date, format) {
  const dt = new Date(date)
  const year = dt.getFullYear()
  const month = (dt.getMonth() + 1).toString().padStart(2, "0")
  const day = dt.getDate().toString().padStart(2, "0")
  return format.split("").map(x => {
    if (x === 'y') return year
    if (x === 'm') return month
    if (x === 'd') return day
    return ""
  }).join("/")
}

export async function createTicketEPN(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, invoiceNumber, giftcardNumber, callback = () => { }) {
  console.log("STARTING VALOR TRANSACTION...")
  localStorage.removeItem("INCOMING_REF_TXN_ID")
  let retryCount = 0
  const storageInterval = setInterval(() => {
    console.log("CHECKING FOR TRANSACTION RESULT...")
    const REF_TXN_ID = localStorage.getItem("INCOMING_REF_TXN_ID")
    if (REF_TXN_ID) {
      console.log("CREATING TICKET!")
      createTicket(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, REF_TXN_ID, invoiceNumber, giftcardNumber)
      fetch(`/api/update-invoice/${invoiceNumber}?amount=${credit}`)
      callback()
      localStorage.removeItem("INCOMING_REF_TXN_ID")
      clearInterval(storageInterval)
      return
    }
    if (retryCount++ > 180) {
      console.log("TRANSACTION TIMEOUT...")
      clearInterval(storageInterval)
    }
  }, 1000)
}

export function createTicket(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, items, transactionID, invoiceNumber, giftcardNumber) {
  const master = new TicketMaster(mid, cash, credit, tax, cardAdminFee, cardAdminFeeTax, discount, tip, "Reef Web", "CLOSED")
  if (transactionID) {
    if (IS_EPN) master.REF_TXN_ID = transactionID
    if (IS_REEF) master.PNRef = transactionID
  }
  if (invoiceNumber) master.invoiceNumber = invoiceNumber
  if (giftcardNumber) master.giftcardNumber = giftcardNumber
  fetch("/api/add-document/TicketMaster", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(master)
  }).then(response => response.json())
    .then(data => {
      for (const item of items) {
        fetch("/api/add-document/TicketDetail", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(new TicketDetail(data.insertedId, mid, item))
        })
        if (item.isGiftCard) {
          for (let i = 0; i < Number(item.quantity); i++) {
            fetch("/api/add-document/GiftCard", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(new GiftCard(mid, true, item.giftcardNumbers[i], currency(item.originalCashPrice, { fromCents: true }).value, null, transactionID).resolveDocument())
            })
          }
        }
      }
    })
}