import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import { get, useForm } from "react-hook-form"
import { PatternFormat } from "react-number-format"
import { EmailRegex, PhoneRegex } from "../utils/regex"
import React from "react"

export default function SupportForm({ setOpen, mid, dba }) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar()
  const form = useForm({ mode: "onBlur", defaultValues: { name: "", phone: "", email: "", reason: "" } })

  // Functions
  function sendSupportTicket() {
    fetch("/api/email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        recipient: "support@paradisepos.com",
        subject: "Reef Pay Support",
        html: `
          <p><b>Merchant:</b> ${dba} - ${mid}
          <p><b>Name:</b> ${form.getValues("name")}</p>
          <p><b>Phone:</b> ${form.getValues("phone")}</p>
          <p><b>Email:</b> ${form.getValues("email")}</p>
          <p><b>Reason:</b> ${form.getValues("reason")}</p>
        `
      })
    }).then(response => {
      if (response.status !== 200) return enqueueSnackbar("Something went wrong!", { variant: "error" })
      enqueueSnackbar("We will get back to you as soon as possible!", { variant: "success" })
      setOpen(false)
    })
  }

  return (
    <>
      <DialogTitle variant="h4" fontWeight="bold" textAlign="center">
        Contact Us
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            Enter your contact information and we will get back to you as quickly as possible!
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...form.register("name", { required: "Name is required." })}
              label="Name"
              helperText={get(form.formState.errors, "name.message")}
              error={Boolean(form.formState.errors.name)}
            />
          </Grid>
          <Grid item xs={12}>
            <PatternFormat
              format="###-###-####"
              customInput={TextField}
              label="Phone Number"
              helperText={get(form.formState.errors, "phone.message")}
              error={Boolean(form.formState.errors.phone)}
              inputProps={{
                ...form.register("phone", {
                  required: "Phone number is required.",
                  pattern: { value: PhoneRegex, message: "Please enter a valid phone number (xxx-xxx-xxxx)." }
                })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...form.register("email", {
                required: "Email is required.",
                pattern: { value: EmailRegex, message: "Please enter a valid email address." }
              })}
              label="Email Address"
              helperText={get(form.formState.errors, "email.message")}
              error={Boolean(form.formState.errors.email)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...form.register("reason", {
                required: "Please provide a short description of your issue.",
                maxLength: { value: 180, message: "Please limit your description to 180 characters." }
              })}
              multiline
              minRows={3}
              maxRows={3}
              label="How can we help you?"
              helperText={get(form.formState.errors, "reason.message")}
              error={Boolean(form.formState.errors.reason)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button color="success" onClick={form.handleSubmit(sendSupportTicket)}>
          Submit
        </Button>
      </DialogActions>
    </>
  )
}