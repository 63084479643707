import backgroundImage from "../public/background.jpg"

export default function createStyle() {
  const style = document.createElement("style")
  style.innerHTML = `
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
  
  #snippet {
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${backgroundImage});
    background-attachment: fixed;
    background-size: cover;
    background-position-y: 40%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 150%;
    z-index: 9999;
  }
  
  input {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #9e9e9e;
  }

  #cancelbtn {
    position: fixed;
    bottom: 0;
    background-color: #f44336;
    width: 35%;
    right: 50%;
    left: 50%;
    transform: translate(-50%, 0%)
  }

  #cancelbtn:hover {
    background-color: #c62828;
  }
  
  button {
    background-color: #4caf50;
    border: 0;
    width: 100%;
    padding: 10px;
    margin: 0px;
    border-radius: 5px;
    color: white;
    font-size: 18;
    letter-spacing: 0.05em;
  }
  
  button:hover {
    background-color: #2e7d32;
  }
  
  #PaygistixCloudWindow {
    display: flex;
    height: 100%;
    justify-content: center;
    margin-top: 200px;
  }
  
  #pgxDeviceForm {
    background-color: white;
    display: flex;
    border-radius: 5px;
    padding: 50px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0 ,0.2);
  }
  
  .pgxMessages {
    width: 100%;
  }
  
  #PaygistixStreamedMessage {
    width: 100%;
    font-size: 30px !important;
    font-weight: lighter;
  }
  
  .pgxFooter {
    display: none;
  }
  
  #PaygistixResult {
    font-size: larger !important;
    font-weight: lighter;
  }
  
  #pgxManualForm {
    background-color: white;
    border-radius: 5px;
    padding: 50px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
  }
  
  #PaygistixManualMessage {
    font-size: 28px !important;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: lighter;
  }
  
  #PaygistixManualResult {
    display: none;
  }
  
  #pgxCardNum {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  #pgxExpMonth {
    width: 31%;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  
  #pgxExpYear {
    width: 32%;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  
  #pgxCvv {
    width: 31%;
    margin-bottom: 20px;
  }
  
  #pgxAddress {
    width: 100%;
    margin-bottom: 20px;
  }
  
  #pgxZip {
    width: 33%;
    margin-bottom: 20px;
  }

  #pgxProgressForm {
    background-color: white;
    border-radius: 5px;
    padding: 50px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  `
  return style
}