import currency from "currency.js"

export default function Transaction(
  avs,
  avsStreet,
  avsZip,
  cvv2,
  cardType,
  cardholder,
  cardNumber,
  cardExp,
  approvalCode,
  authAmount,
  authDate,
  batchID,
  dateTime,
  expDate,
  invoiceNumber,
  manual,
  processor,
  register,
  status,
  reversalFlag,
  settleDate,
  settleFlag,
  transactionID,
  tip,
  total,
  type,
  voidFlag,
  source,
  token
) {
  this.avs = avs
  this.avsStreet = avsStreet
  this.avsZip = avsZip
  this.cvv2 = cvv2
  this.cardType = String(cardType).toUpperCase()
  this.cardholder = cardholder
  this.cardNumber = cardNumber
  this.cardExp = cardExp
  this.approvalCode = approvalCode
  this.authAmount = currency(authAmount).value
  this.authDate = new Date(authDate)
  this.batchID = batchID
  this.dateTime = new Date(dateTime)
  this.date = new Date(dateTime).toLocaleDateString("en-us", { year: "numeric", month: "2-digit", day: "2-digit" })
  this.time = new Date(dateTime).toLocaleTimeString("en-us", { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" })
  this.expDate = expDate
  this.invoiceNumber = invoiceNumber
  this.manual = manual
  this.processor = processor
  this.register = register
  this.status = status
  this.reversalFlag = Number(reversalFlag)
  this.settleDate = new Date(settleDate)
  this.settleFlag = Number(settleFlag)
  this.transactionID = transactionID
  this.tip = currency(tip).value
  this.total = currency(total).value
  this.type = type
  this.voidFlag = Number(voidFlag)
  this.source = source
  this.token = token
}