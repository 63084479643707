module.exports = {
  // Server Secrets
  HOST: '127.0.0.1',
  FRONTEND_PORT: 3000,
  BACKEND_PORT: 5001,

  // Reef Secrets
  PARADISE_SOURCE_KEY: "e6d64b25e0f7eebda0d4ebad26377def19de135a7390431f730b97833b836391f4d46e339908abbc06e5eedfe76ce64f77be056dc86354eb9488102746e5f982",

  // Mongo Secrets
  DATABASE_NAME: process.env.REACT_APP_GATEWAY,
  DEV_DATABASE_URL: "mongodb://localhost:27017",
  DATABASE_URL: "mongodb+srv://mschulz:SPScards1@paradisepos.vcz8f.mongodb.net/?retryWrites=true&w=majority",

  // Mailgun Secrets
  MAILGUN_APIKEY: '7185278893efc583b4a962051bb83e84-8a084751-3a990899',
  MAILGUN_DOMAIN: 'mg.reef-pay.com',

  // Twilio Secrets
  TWILIO_SID: 'AC15055e232242f2a1a50bbaf52a87df9c',
  TWILIO_AUTH_TOKEN: '1333a92bb3d2a990abfd7954f0d7b881',
  TWILIO_PHONE: '+19037306995',
  MESSAGING_SERVICE_SID: "MGd1072bb4d928a546374356ac7970dc30",

  // Paylink Secrets
  PAYLINK_URL: "pay.link",
  DEV_PAYLINK_URL: "dev.pay.link",
}
