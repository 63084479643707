import { Box, Typography } from "@mui/material"
import React from "react"
import { IS_EPN } from "../global"

export default function Footer() {
  return (
    <Box bgcolor="lightgrey" padding={1}>
      <Typography variant="h6" align="center">
        Powered By {IS_EPN ? "ePN" : "Reef Pay"}
      </Typography>
    </Box>
  )
}