import { Box, Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@mui/material"
import React from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import Searchbar from "./components/Searchbar"
import Sidebar from "./components/Sidebar"
import Footer from "./components/Footer"
import useMongo from "./utils/useMongo"
import TotalSales from "./components/Reports/Total Sales/TotalSales"
import Approved from "./components/Status/Approved"
import Declined from "./components/Status/Declined"

// Lazy-loading allows the browser to load only the necessary components in chunks
const Login = React.lazy(() => import("./components/Login/Login"))
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"))
const VirtualTerminal = React.lazy(() => import("./components/Virtual Terminal/VirtualTerminal"))
const Invoices = React.lazy(() => import("./components/Invoices/Invoices"))
const InvoicePay = React.lazy(() => import("./components/Invoices/InvoicePay"))
const InvoiceReceipt = React.lazy(() => import("./components/Invoices/InvoiceReceipt"))
const Plans = React.lazy(() => import("./components/Plans/Plans"))
const Customers = React.lazy(() => import("./components/Customers/Customers"))
const BatchManager = React.lazy(() => import("./components/Batch Manager/BatchManager"))
const TransactionDetail = React.lazy(() => import("./components/Batch Manager/TransactionDetail"))
const Departments = React.lazy(() => import("./components/Inventory/Departments/Departments"))
const Categories = React.lazy(() => import("./components/Inventory/Categories/Categories"))
const Items = React.lazy(() => import("./components/Inventory/Items/Items"))
const Modifiers = React.lazy(() => import("./components/Inventory/Modifiers/Modifiers"))
const Vendors = React.lazy(() => import("./components/Inventory/Vendors/Vendors"))
const GiftCards = React.lazy(() => import("./components/Gift Cards/GiftCards"))
const Discounts = React.lazy(() => import("./components/Discounts/Discounts"))
const BatchSummary = React.lazy(() => import("./components/Reports/BatchSummary"))
const TransactionSearch = React.lazy(() => import("./components/Reports/TransactionSearch"))
const RecurringBilling = React.lazy(() => import("./components/Reports/RecurringBilling"))
const CardExpiration = React.lazy(() => import("./components/Reports/CardExpiration"))
const CurrentInventory = React.lazy(() => import("./components/Reports/CurrentInventory"))
const ItemsSold = React.lazy(() => import("./components/Reports/ItemsSold"))
const SalesTax = React.lazy(() => import("./components/Reports/SalesTax"))
const Ticket = React.lazy(() => import("./components/Tickets/Ticket"))
const Tickets = React.lazy(() => import("./components/Tickets/Tickets"))
const GeneralSettings = React.lazy(() => import("./components/Settings/General Settings/GeneralSettings"))
const MerchantSettings = React.lazy(() => import("./components/Settings/Merchant (CRM)/MerchantSettings"))
const Users = React.lazy(() => import("./components/Settings/Users/Users"))
const RenderPDF = React.lazy(() => import("./components/RenderPDF"))
const Page404 = React.lazy(() => import("./components/Page404"))

export default function App() {
  // States
  const [merchants, setMerchants] = React.useState(JSON.parse(sessionStorage.getItem("merchants")) || [])
  const [merchant, setMerchant] = React.useState({})
  const [merchantSettings, setMerchantSettings] = React.useState({ pay: true })
  const [payLinkApiKey, setPayLinkApiKey] = React.useState({})

  // Hooks
  const reefSettings = useMongo("Setting", merchant.mid) // Only the first settings profile will be used
  const loggedIn = Boolean(sessionStorage.getItem("merchants") && sessionStorage.getItem("username") && sessionStorage.getItem("role"))

  // Functions
  function fetchMerchants() {
    // Do not fetch merchants if the user is not logged in
    if (!loggedIn) return
    const role = sessionStorage.getItem("role")

    if (role === "Merchant") return // No additional merchant retrieval needed

    // Retrieve all Reef merchants
    if (role === "Admin" || role === "Office") {
      fetch("/api/get-merchants")
        .then(response => response.json())
        .then(data => setMerchants(data.sort((a, b) => { return a.dba < b.dba ? -1 : a.dba > b.dba ? 1 : 0 })))
    }

    // Retrieve any merchants associated with an agent, and append with any merchants returned by default
    if (role === "Agent") {
      fetch("/api/get-agent-merchants", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ aid: sessionStorage.getItem("agentID") })
      }).then(response => response.json())
        .then(data => setMerchants(data.concat(merchants).sort((a, b) => { return a.dba < b.dba ? -1 : a.dba > b.dba ? 1 : 0 })))
    }
  }

  function fetchMerchantSettings() {
    // Do not fetch merchant settings if the user is not logged in
    if (!loggedIn) return

    // Clear previous merchant settings
    setMerchantSettings({ pay: true })  // This avoids intrusive license dialog when loading

    if (!merchant.mid) return
    // Retrieve merchant settings from CRM
    fetch(`/api/get-merchant-settings/${merchant.mid}`)
      .then(response => response.json())
      .then(data => setMerchantSettings(data))
      .catch(err => {
        console.log(err)
        // Show intrusive license dialog
        setMerchantSettings({ pay: false })
      })
  }

  function updateMerchantSettings(merchant) {
    fetch(`/api/update-document/Merchant/${merchant._id}?parseObjectId=false`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ $set: merchant })
    }).then(response => response.json())
      .then(data => {
        console.log(data)
        setMerchantSettings(merchant)
      })
  }

  function fetchApiKey() {
    const username = encodeURIComponent(merchantSettings.paylinkUsername)
    const password = encodeURIComponent(merchantSettings.paylinkPassword)

    if (username === "undefined" && password === "undefined") {
      setPayLinkApiKey(undefined)
      console.log("No PayLink credentials provided!")
      return
    }

    fetch(`/api/get-api-key?Username=${username}&Password=${password}`)
      .then(response => response.json())
      .then(data => setPayLinkApiKey(data.Token))
      .catch(error => console.log(error))
  }

  // Effects
  React.useEffect(fetchMerchants, [])
  React.useEffect(() => setMerchant(merchants.find(m => m.mid === localStorage.getItem("lastMid")) || merchants[0] || {}), [merchants])
  React.useEffect(fetchMerchantSettings, [merchant])
  React.useEffect(fetchApiKey, [merchantSettings])

  return (
    <>
      {/* Intrusive dialog to prevent usage if licensing is disabled or missing */}
      {loggedIn && Boolean(merchantSettings.pay) === false && sessionStorage.getItem("role") === "Merchant" &&
        <Dialog open={true}>
          <DialogTitle align="center">
            <Typography variant="h4" color="error" fontWeight="bold">
              NO LICENSE
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography fontWeight="bold">
              Please contact your agent to confirm licensing for Reef Pay.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              sessionStorage.removeItem("merchants")
              sessionStorage.removeItem("username")
              sessionStorage.removeItem("role")
              sessionStorage.removeItem("agentID")
              window.location.href = "/login"
            }}>
              Logout
            </Button>
          </DialogActions>
        </Dialog>}
      {Redirect(loggedIn)}
      <CssBaseline />
      {/* {loggedIn && <UserAgreement />} */}
      <Box display="flex" height="100%">
        {loggedIn && <Sidebar mid={merchant.mid} dba={merchant.dba} />}
        <Box display="flex" flexDirection="column" flex={1} justifyContent="space-between" height="100vh">
          <Box>
            {loggedIn && <Searchbar merchants={merchants} merchant={merchant} setMerchant={setMerchant} />}
            <React.Suspense fallback={<LinearProgress />}>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route
                  path="dashboard"
                  element={
                    <Dashboard
                      mid={merchant.mid}
                      gatewayUsername={merchantSettings.gatewayUsername}
                      gatewayPassword={merchantSettings.gatewayPassword}
                      gatewayKey={merchantSettings.gatewayKey}
                    />
                  }
                />
                <Route
                  path="virtualterminal"
                  element={
                    <VirtualTerminal
                      mid={merchant.mid}
                      cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                      cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                      gatewayUsername={merchantSettings.gatewayUsername}
                      gatewayPassword={merchantSettings.gatewayPassword}
                      gatewayKey={merchantSettings.gatewayKey}
                      paylinkApiKey={payLinkApiKey}
                      promptTip={reefSettings.firstDocument.promptTip}
                      merchantName={merchantSettings.name}
                      address={reefSettings.firstDocument.addressToUse === "legal" ? merchantSettings.address : merchantSettings.dbaAddress}
                      companyPhone={merchantSettings.phoneNumber}
                      defaultTaxRate={reefSettings.firstDocument.defaultTaxRate}
                    />
                  }
                />
                <Route path="invoices">
                  <Route
                    index
                    element={
                      <Invoices
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                        cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                        cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                        promptTip={reefSettings.firstDocument.promptTip != null ? reefSettings.firstDocument.promptTip : true}
                        promptShipping={reefSettings.firstDocument.promptShipping != null ? reefSettings.firstDocument.promptShipping : true}
                        promptDiscount={reefSettings.firstDocument.promptDiscount != null ? reefSettings.firstDocument.promptDiscount : true}
                        invoiceStartingNumber={reefSettings.firstDocument.invoiceStartingNumber || 0}
                        merchantName={merchant.dba}
                        merchantEmail={reefSettings.firstDocument.email}
                        emailFrom={reefSettings.firstDocument.emailFrom}
                        subjectPrefix={reefSettings.firstDocument.subjectPrefix}
                        merchantLogo={reefSettings.firstDocument.logo}
                        defaultTaxRate={reefSettings.firstDocument.defaultTaxRate}
                      />
                    }
                  />
                  <Route path=":id" element={<InvoiceReceipt />} />
                </Route>
                <Route path="pay/:id" element={<InvoicePay />} />
                <Route
                  path="plans"
                  element={
                    <Plans
                      mid={merchant.mid}
                      pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                      cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                    />
                  }
                />
                <Route
                  path="customers"
                  element={
                    <Customers
                      mid={merchant.mid}
                      pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                      cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                      gatewayUsername={merchantSettings.gatewayUsername}
                      gatewayPassword={merchantSettings.gatewayPassword}
                      gatewayKey={merchantSettings.gatewayKey}
                      paylinkApiKey={payLinkApiKey}
                    />
                  }
                />
                <Route path="batchmanager">
                  <Route
                    index
                    element={
                      <BatchManager
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                        merchantName={merchantSettings.name}
                        gatewayUsername={merchantSettings.gatewayUsername}
                        gatewayPassword={merchantSettings.gatewayPassword}
                        gatewayKey={merchantSettings.gatewayKey}
                        paylinkApiKey={payLinkApiKey}
                      />
                    }
                  />
                  <Route
                    path="transaction"
                    element={
                      <TransactionDetail
                        mid={merchant.mid}
                        merchantName={merchantSettings.name}
                        cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                        cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                        address={reefSettings.firstDocument.addressToUse === "legal" ? merchantSettings.address : merchantSettings.dbaAddress}
                        companyPhone={merchantSettings.phoneNumber}
                        gatewayUsername={merchantSettings.gatewayUsername}
                        gatewayPassword={merchantSettings.gatewayPassword}
                        gatewayKey={merchantSettings.gatewayKey}
                        paylinkApiKey={payLinkApiKey}
                      />
                    }
                  />
                </Route>
                <Route
                  path="giftcards"
                  element={
                    <GiftCards
                      mid={merchant.mid}
                      cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                      cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                      gatewayUsername={merchantSettings.gatewayUsername}
                      gatewayPassword={merchantSettings.gatewayPassword}
                      gatewayKey={merchantSettings.gatewayKey}
                      paylinkApiKey={payLinkApiKey}
                      pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                    />
                  }
                />
                <Route path="inventory">
                  <Route
                    path="departments"
                    element={
                      <Departments
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="categories"
                    element={
                      <Categories
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="items"
                    element={
                      <Items
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                        cardAdminFeePercent={merchantSettings.cardAdminFeePercent}
                        cardAdminFeeAmount={merchantSettings.cardAdminFeeAmount}
                      />
                    }
                  />
                  <Route
                    path="modifiers"
                    element={
                      <Modifiers
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="vendors"
                    element={
                      <Vendors
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                </Route>
                <Route
                  path="discounts"
                  element={
                    <Discounts
                      mid={merchant.mid}
                      pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                    />
                  }
                />
                <Route path="reports">
                  <Route
                    path="batchsummary"
                    element={
                      <BatchSummary
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                        gatewayUsername={merchantSettings.gatewayUsername}
                        gatewayPassword={merchantSettings.gatewayPassword}
                        gatewayKey={merchantSettings.gatewayKey}
                      />
                    }
                  />
                  <Route
                    path="transactionsearch"
                    element={
                      <TransactionSearch
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                        gatewayUsername={merchantSettings.gatewayUsername}
                        gatewayPassword={merchantSettings.gatewayPassword}
                        gatewayKey={merchantSettings.gatewayKey}
                      />
                    }
                  />
                  <Route
                    path="recurringbilling"
                    element={
                      <RecurringBilling
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="cardexpiration"
                    element={
                      <CardExpiration
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="currentinventory"
                    element={
                      <CurrentInventory
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="itemssold"
                    element={
                      <ItemsSold
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="salestax"
                    element={
                      <SalesTax
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path="totalsales"
                    element={
                      <TotalSales
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                        gatewayUsername={merchantSettings.gatewayUsername}
                        gatewayPassword={merchantSettings.gatewayPassword}
                        gatewayKey={merchantSettings.gatewayKey}
                      />
                    }
                  />
                </Route>
                <Route path="pos">
                  <Route path="batchsummary" element={<BatchSummary pageSize={10} />} />
                  <Route path="transactionsearch" element={<TransactionSearch pageSize={10} />} />
                  <Route path="recurringbilling" element={<RecurringBilling pageSize={10} />} />
                  <Route path="cardexpiration" element={<CardExpiration pageSize={10} />} />
                  <Route path="currentinventory" element={<CurrentInventory pageSize={10} />} />
                  <Route path="itemssold" element={<ItemsSold pageSize={10} />} />
                  <Route path="salestax" element={<SalesTax pageSize={10} />} />
                  <Route path="totalsales" element={<TotalSales pageSize={10} />} />
                </Route>
                <Route path="tickets">
                  <Route
                    index
                    element={
                      <Tickets
                        mid={merchant.mid}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                  <Route
                    path=":id"
                    element={
                      <Ticket
                        merchantName={merchant.dba}
                        merchantEmail={reefSettings.firstDocument.email}
                        paylinkApiKey={payLinkApiKey}
                      />
                    }
                  />
                </Route>
                <Route path="settings">
                  <Route
                    path="general"
                    element={
                      <GeneralSettings
                        mid={merchant.mid}
                        settings={reefSettings.firstDocument}
                        updateSettings={reefSettings.updateDocument}
                      />
                    }
                  />
                  <Route
                    path="merchant"
                    element={
                      <MerchantSettings
                        mid={merchant.mid}
                        companyName={merchantSettings.name}
                        legalAddress={merchantSettings.address || {}}
                        dbaAddress={merchantSettings.dbaAddress || {}}
                        phone={merchantSettings.phoneNumber}
                        settings={reefSettings.firstDocument}
                        updateSettings={reefSettings.updateDocument}
                        merchant={merchantSettings}
                        updateMerchant={updateMerchantSettings}
                      />
                    }
                  />
                  <Route
                    path="usermanager"
                    element={
                      <Users
                        merchants={merchants}
                        pageSize={reefSettings.firstDocument.rowsReturnedPerPage || 10}
                      />
                    }
                  />
                </Route>
                <Route path="pdf/:file" element={<RenderPDF />} />
                <Route path="*" element={<Page404 />} />
                <Route path="status">
                  <Route
                    path="complete"
                    element={<Approved />}
                  />
                  <Route
                    path="failure"
                    element={<Declined />}
                  />
                </Route>
              </Routes>
            </React.Suspense>
          </Box>
          {loggedIn && <Footer />}
        </Box>
      </Box >
    </>
  )
}

function Redirect(loggedIn) {
  const navigate = useNavigate()
  // If login page is accessed while already being logged in
  // redirect to the default page
  if (loggedIn && window.location.pathname === "/login")
    navigate("/dashboard")

  // Redirect if page is not ignored
  if (!loggedIn) {
    const REDIRECT_IGNORE = ["login", "pay", "pdf", "pos", "status"]
    let path = window.location.pathname.split("/")
    for (const ignore of REDIRECT_IGNORE)
      if (path.includes(ignore)) return
    navigate("/login")
  }
}