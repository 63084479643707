import { useSnackbar } from "notistack"
import React from "react"

export default function useMongo(collection, mid, ignoreDeletion = false, ignoreActive = true, allowAlerts = true) {
  // Hooks
  const { enqueueSnackbar } = useSnackbar()

  // States
  const [shouldUpdate, setShouldUpdate] = React.useState(false)
  const [firstDocument, setFirstDocument] = React.useState({})
  const [documents, setDocuments] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  // Effects
  React.useEffect(() => setShouldUpdate(true), [mid])
  React.useEffect(() => {
    if (shouldUpdate) {
      retrieveDocuments(mid)
      setShouldUpdate(false)
    }
  }, [shouldUpdate])

  // This should not be used often. The hook should control its state independently.
  function forceUpdate() { setShouldUpdate(true) }

  function searchDocuments(search, parseFn = document => document) {
    return documents.map(document => parseFn(document)).filter(document => Object.values(document).some(value => String(value).toLowerCase().includes(search.toLowerCase())))
  }

  function queryDocument(query) {
    const keys = Object.keys(query)
    return documents.find(document => keys.every(key => query[key] === document[key]))
  }

  function queryDocuments(query) {
    const keys = Object.keys(query)
    return documents.filter(document => keys.every(key => query[key] === document[key]))
  }

  function retrieveDocuments(mid) {
    setLoading(true)
    fetch(`/api/get-documents/${collection}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ mid: mid, ignoreDeletion: ignoreDeletion, ignoreActive: ignoreActive })
    }).then(response => response.json())
      .then(data => {
        setDocuments(data)
        setFirstDocument(data[0] || {})
        setLoading(false)
      })
  }

  function deleteDocument(documentId) {
    fetch(`/api/update-document/${collection}/${documentId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ $set: { isDeleted: true } })
    }).then(() => setShouldUpdate(true))
  }

  async function createDocument(document) {
    return new Promise((resolve, reject) => {
      fetch(`/api/add-document/${collection}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(document)
      }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            console.log(data)
            if (allowAlerts) enqueueSnackbar("Document created successfully!", { variant: "success" })
            setShouldUpdate(true)
            resolve(data)
          })
        } else {
          enqueueSnackbar("Something went wrong.", { variant: "error" })
          reject()
        }
      })
    })
  }

  async function updateDocument(documentId, updateForm) {
    return new Promise((resolve, reject) => {
      fetch(`/api/update-document/${collection}/${documentId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updateForm)
      }).then(response => {
        if (response.status === 200) {
          if (allowAlerts) enqueueSnackbar("Changes saved!", { variant: "success" })
          setShouldUpdate(true)
          resolve()
        } else {
          enqueueSnackbar("Something went wrong.", { variant: "error" })
          reject()
        }
      })
    })
  }

  return {
    documents: documents,
    firstDocument: firstDocument,
    loading: loading,
    searchDocuments: searchDocuments,
    queryDocument: queryDocument,
    queryDocuments: queryDocuments,
    deleteDocument: deleteDocument,
    createDocument: createDocument,
    updateDocument: updateDocument,
    forceUpdate: forceUpdate
  }
}