import { AccountCircle, ArrowDropDown } from "@mui/icons-material"
import { AppBar, Autocomplete, Box, Button, Grid, IconButton, Paper, Popover, Slide, TextField, useScrollTrigger } from "@mui/material"
import React from "react"
import { isMobile } from "react-device-detect"

export default function Searchbar({ merchants, merchant, setMerchant }) {
  // Hooks
  const trigger = useScrollTrigger({ disableHysteresis: true })

  // States
  const [anchorEl, setAnchorEl] = React.useState(null)

  return (
    <Slide appear={true} direction="down" in={!trigger || !isMobile}>
      <AppBar position="sticky" elevation={1} sx={{ bgcolor: "primary.contrastText" }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={10} sm={6} md={4}>
            <Autocomplete
              autoHighlight
              disableClearable
              options={merchants}
              getOptionLabel={option => option.dba || "..."}
              value={merchant}
              popupIcon={<ArrowDropDown color="primary" />}
              onChange={(event, value) => {
                if (value === null) return
                setMerchant(value)
                localStorage.setItem("lastMid", value.mid)
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.mid}>
                  {option.dba}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="No Store Selected"
                  onFocus={event => event.target.select()}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    sx: {
                      paddingLeft: 5,
                      color: "primary.main",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: 20
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={6} container alignItems="center" justifyContent="flex-end">
            {/* <Hidden smDown>
              <Grid item xs={8}>
                <TextField
                  disabled
                  InputProps={{ startAdornment: (<Search color="primary" />) }}
                  tabIndex={-1}
                />
              </Grid>
            </Hidden> */}
            <Grid item>
              <IconButton
                onClick={event => setAnchorEl(anchorEl ? null : event.currentTarget)}
                tabIndex={-1}
              >
                <AccountCircle color="primary" sx={{ fontSize: 52 }} />
              </IconButton>
              <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <Box padding={1} component={Paper}>
                  <Button
                    variant="text"
                    onClick={() => {
                      sessionStorage.removeItem("merchants")
                      sessionStorage.removeItem("username")
                      sessionStorage.removeItem("role")
                      sessionStorage.removeItem("agentID")
                      window.location.href = "/login"
                    }}
                  >
                    Logout
                  </Button>
                </Box>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </Slide>
  )
}