export const BATCH_CSV_HEADERS = ["Batch ID", "Settle Date", "Net Trx", "Net Amt", "Tip Amt"]
export const TRANSACTION_CSV_HEADERS = ["Date", "Time", "Type", "Cardholder", "Card Type", "Card Number", "AVS", "CVV2", "Amount", "Auth", "Order", "Transaction ID", "Invoice", "Source", "Batch ID", "Status"]
export const INVENTORY_CSV_HEADERS = ["Item Name", "SKU", "Department", "Category", "Vendor", "Par", "Quantity", "Cost", "Price"]
export const ITEMS_SOLD_CSV_HEADERS = ["Item Name", "SKU", "Department", "Category", "Quantity", "Cost", "Price", "Profit", "Markup"]
export const RECURRING_BILLING_CSV_HEADERS = ["Customer", "Customer ID", "Transaction Date", "Payment Amount"]

export const CARD_EXPIRATION_CSV_HEADERS = [
  { label: "Customer", key: "customerName" },
  { label: "Customer ID", key: "customerID" },
  { label: "Cardholder", key: "cardholder" },
  { label: "Card Type", key: "cardType" },
  { label: "Card Number", key: "cardNumber" },
  { label: "Expiration Date", key: "cardExp" }
]

export const SALES_TAX_CSV_HEADERS = ["Name", "Quantity", "Subtotal", "Card Admin Fee", "Tax", "Card Admin Fee Tax", "Total"]
export const TOTAL_SALES_PAYMENT_CSV_HEADERS = ["Type", "Total", "Quantity"]
export const TOTAL_SALES_CREDIT_CSV_HEADERS = ["Name", "Transaction ID", "Card Number", "Tip", "Total"]
export const TOTAL_SALES_REFUND_CSV_HEADERS = ["Name", "Transaction ID", "Card Number", "Total"]
export const TOTAL_SALES_DEPARTMENT_CSV_HEADERS = ["Department", "Net Sales", "Discounts", "Quantity"]
export const TOTAL_SALES_CATEGORY_CSV_HEADERS = ["Category", "Net Sales", "Discounts", "Quantity"]
export const TOTAL_SALES_DISCOUNT_CSV_HEADERS = ["Type", "Total", "Quantity"]
export const TOTAL_SALES_VOID_CSV_HEADERS = ["Type", "Total", "Quantity"]