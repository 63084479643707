import { useSnackbar } from "notistack"

export default function useExporter() {
  // Hooks
  const { enqueueSnackbar } = useSnackbar()

  // Functions
  function _export(mid, filename, data) {
    enqueueSnackbar("Exporting...", { variant: "info" })
    fetch("/api/pos/export-report", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ mid: mid, filename: filename, report: data })
    }).then(response => {
      if (response.status === 200)
        enqueueSnackbar("Your report has been emailed!", { variant: "success" })
    })
  }

  return { export: _export }
}