import React from "react"
import { Box, Grid } from "@mui/material"
import declinedGif from "../../public/declined.gif"

export default function Failure() {
  // Close the tab after a short wait.
  setTimeout(() => window.close(), 3000)
  return (
    <Box height="100vh" bgcolor="primary.contrastText" paddingTop={15}>
      <Grid container justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <img src={declinedGif} alt="..." />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <h1>DECLINED</h1>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <h4>Your transaction has been declined.</h4>
        </Grid>
      </Grid>
    </Box>
  )
}