import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import ReefTheme from "./themes/ReefTheme"
import EpnTheme from "./themes/EpnTheme"
import { ThemeProvider } from "@mui/material/styles"
import { SnackbarProvider } from "notistack"
import { IS_EPN } from "./global"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={IS_EPN ? EpnTheme : ReefTheme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"))